
import { Component, Vue } from 'vue-property-decorator';
@Component({ })
class Countdown extends Vue {
    private mounted(){
        this.start();   
    }

    private number: number = 3;
    private loading = false;

    public start(){

        setTimeout(() => {
            this.loading = true;

            setTimeout(() => {
                this.number -= 1;
                const interval = setInterval(() => {
                    this.number -= 1;
                    if(this.number < 1){
                        clearInterval(interval);

                        setTimeout(() => {
                            // this.$store.commit('replace', { page: 'multiplayer-question', transition: 'crossFade' });
                        }, 150);
                    }
                }, 1000);
            }, 450);

        }, 300);

    }
}

export default Countdown;
