
import { Component, Vue } from 'vue-property-decorator';

@Component({ })
class ShareModal extends Vue {

    private isOpened: boolean = false;
    private resolve: (() => void) | null = null;

    public share(){
        return new Promise((resolve) => {
            this.isOpened = true;
            this.resolve = resolve;
        });
    }

    private     (){
        this.isOpened = false;
        this.resolve!();
    }
}

export default ShareModal;
