
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class ResultListItem extends Vue {

    @Prop({ type: String, required: true })
    private name!: string;

    @Prop({ type: Number, required: true })
    private position!: number;

    @Prop({ type: String, required: true })
    private points!: string;

    @Prop({ type: String, required: true })
    private avatar!: string;

    @Prop({ type: String, required: true })
    private status!: "up" | "down" | "same";
}

export default ResultListItem;
