
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class RankingItem extends Vue {

    @Prop({ type: Number, required: true })
    private position!: number;

    @Prop({ type: Number, required: true })
    private points!: number;

    @Prop({ type: String, required: true })
    private name!: string;

    @Prop({ type: String, required: true })
    private avatar!: string;

    @Prop({ type: String, required: true })
    private status!: 'up' | 'down' | 'same';
}

export default RankingItem;
