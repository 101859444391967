
import { Component, Vue } from 'vue-property-decorator';
import RatingModal from './RatingModal.vue';

@Component({ components: { RatingModal } })
class ResultadoTeoricoPage extends Vue {
  
}

export default ResultadoTeoricoPage;
