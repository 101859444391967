
import { Component, Vue } from 'vue-property-decorator';

// @ts-ignore
import { mask } from 'ke-the-mask';

@Component({ directives: { mask } })
class MultiplayerMainPage extends Vue {

    private pin: string = '';
    private loading: boolean = false;

    private startMatch() {
        this.loading = true;
        setTimeout(() => {
            this.$store.commit('replace', { page: 'countdown', transition: 'crossFade' });
        }, 2000);
    }
}

export default MultiplayerMainPage;
