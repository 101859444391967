
import { Component, Vue } from 'vue-property-decorator';
import RankingItem from './RankingItem.vue';
import ResultListItem from './ResultListItem.vue';
import RatingModal from './RatingModal.vue';
import ShareModal from './ShareModal.vue';

@Component({ components: { RankingItem, ResultListItem, RatingModal, ShareModal } })
class Result extends Vue {
    private rate() {
        (this.$refs.modal as RatingModal).rate()
            .then(() => (this.$refs.shareModal as ShareModal).share())
            .then(() => this.$store.commit('goBack'));
    }
}

export default Result;
