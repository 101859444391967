
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class PTag extends Vue {
    
    @Prop({ type: Array, required: true })
    private value!: string[];

    @Prop({ type: String, required: true })
    private inputValue!: string;
    
    @Prop({ type: String, required: true })
    private label!: string;


    private get _value() {
        return this.value;
    }

    private set _value(value: string[]) {
        this.$emit('input', value);
    }
}

export default PTag;
