
import { Component, Vue } from 'vue-property-decorator';
import Tag from '@/components/p-Tag.vue';

@Component({ components: { Tag } })
class RatingModal extends Vue {

    private rating: number = 0;
    private tags: string[] = [];
    private resolve: (() => void)| null = null;
    private isOpened: boolean = false;

    public rate() {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.isOpened = true;
        });
    }

    private finish() {
        this.isOpened = false;
        this.resolve!();
    }
}

export default RatingModal;
