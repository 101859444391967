
import Questao from '@/models/Questao';
import { Component, Vue, Watch } from 'vue-property-decorator';
import sentenceCase from '@/utils/sentenceCase';
import PQuestaoCard from '@/components/layouts/p-QuestaoCard.vue';

import ResultListItem from './ResultListItem.vue';

@Component({ filters: { sentenceCase }, components: { PQuestaoCard, ResultListItem } })
class MultiplayerQuestion extends Vue {

    private tempo: number = 100000;
    private indexQuestaoAtual: number = 0;
    private aswered: boolean = false;

    // ========= Propriedades computadas ===========
    private get questaoAtual() {
        return this.questoes[this.indexQuestaoAtual];
    }

    private get temaQuestaoAtual(){
        return 'ver como vai fazer isso';
    }

    // ============= M�todos ==================
    private timerFinished(){
        console.log('tempo acabou');
    }

    private getColorBar(index: number, questao: Questao){
        if(index == this.indexQuestaoAtual) {
            return 'var(--gray-1)';
        }
        else if(this.$route.name == 'conferencia-teorico') {
            const idAlternativaCorreta = questao.alternativas.find(a => a.alternativaCorreta)!.id;
            return questao.alternativaSelecionada == idAlternativaCorreta ? '#31BE9E' : '#E65252';
        }
        else if(questao.alternativaSelecionada > 0) {
            return 'var(--primary-color)';
        }
        else {
            return 'var(--border-1)';
        }
    }

    // ===========  Observadores ===============
    @Watch('questaoAtual.alternativaSelecionada')
    private onSelectAlternative(){
        setTimeout(() => {
            this.aswered = true;

            setTimeout(() => {
                this.$store.commit('replace', { page: 'multiplayer-result', transition: 'crossFade' });
            }, 3000);
        }, 1000);
    }

    private questoes = [
        {
            "descricaoQuestao": "A PLACA (A-26b), INDICA QUE O SENTIDO DE CIRCULA��O �:",
            "id": 389,
            "alternativaSelecionada": 0,
            "alternativas": [
                {
                    "id": 1634,
                    "descricao": "�NICO PASSANDO PARA DUPLO",
                    "alternativaCorreta": false
                },
                {
                    "id": 1635,
                    "descricao": "DUPLO ",
                    "alternativaCorreta": true
                },
                {
                    "id": 1636,
                    "descricao": "DUPLO NA VIA TRANSVERSAL",
                    "alternativaCorreta": false
                },
                {
                    "id": 1637,
                    "descricao": "�NICO",
                    "alternativaCorreta": false
                }
            ],
            "imagensQuestao": [
                {
                    "id": 227,
                    "url": "https://pilotar.blob.core.windows.net/imagens-questoes-pilotar/34391b6c-4fcf-4e0e-9300-f98565b8d7b3.png?st=2020-02-13T12%3A24%3A24Z&se=2021-02-13T12%3A24%3A00Z&sp=rl&sv=2018-03-28&sr=c&sig=RwwQGlYbjycDUzx%2F%2B%2FSkkf6QSPIdalFMsRFA3TpN9KY%3D"
                }
            ],
            "temaId": 1
        },
        {
            "descricaoQuestao": "O QUE SIGNIFICA A PLACA (A-24)?",
            "id": 836,
            "alternativaSelecionada": 0,
            "alternativas": [
                {
                    "id": 3301,
                    "descricao": "�REA ESCOLAR",
                    "alternativaCorreta": false
                },
                {
                    "id": 3302,
                    "descricao": "PASSSAGEM SINALIZADA DE ESCOLARES",
                    "alternativaCorreta": false
                },
                {
                    "id": 3303,
                    "descricao": "OBRAS ",
                    "alternativaCorreta": true
                },
                {
                    "id": 3304,
                    "descricao": "HOMENS TRABALHANDO",
                    "alternativaCorreta": false
                }
            ],
            "imagensQuestao": [
                {
                    "id": 217,
                    "url": "https://pilotar.blob.core.windows.net/imagens-questoes-pilotar/768bef53-866a-48a6-99fc-9441f58f1054.png?st=2020-02-13T12%3A24%3A24Z&se=2021-02-13T12%3A24%3A00Z&sp=rl&sv=2018-03-28&sr=c&sig=RwwQGlYbjycDUzx%2F%2B%2FSkkf6QSPIdalFMsRFA3TpN9KY%3D"
                }
            ],
            "temaId": 1
        },
        {
            "descricaoQuestao": "O QUE SIGNIFICA A PLACA (R-26)?",
            "id": 887,
            "alternativaSelecionada": 0,
            "alternativas": [
                {
                    "id": 3504,
                    "descricao": "SENTIDO �NICO",
                    "alternativaCorreta": false
                },
                {
                    "id": 3505,
                    "descricao": "SENTIDO DE CIRCULACA��O DA VIA",
                    "alternativaCorreta": false
                },
                {
                    "id": 3506,
                    "descricao": "PASSAGEM OBRIGAT�RIA",
                    "alternativaCorreta": false
                },
                {
                    "id": 3507,
                    "descricao": "SIGA EM FRENTE ",
                    "alternativaCorreta": true
                }
            ],
            "imagensQuestao": [
                {
                    "id": 120,
                    "url": "https://pilotar.blob.core.windows.net/imagens-questoes-pilotar/170f8b3f-4ff6-4a50-b1ce-9eb6e5c9ab89.png?st=2020-02-13T12%3A24%3A24Z&se=2021-02-13T12%3A24%3A00Z&sp=rl&sv=2018-03-28&sr=c&sig=RwwQGlYbjycDUzx%2F%2B%2FSkkf6QSPIdalFMsRFA3TpN9KY%3D"
                }
            ],
            "temaId": 1
        },
        {
            "descricaoQuestao": "A PLACA (A-24), INDICA QUE ADIANTE VOC� VAI ENCONTRAR OBRAS:",
            "id": 747,
            "alternativaSelecionada": 0,
            "alternativas": [
                {
                    "id": 3110,
                    "descricao": "NUMA BIFURCA��O ADIANTE",
                    "alternativaCorreta": false
                },
                {
                    "id": 3111,
                    "descricao": "CONCLU�DAS RECENTEMENTE",
                    "alternativaCorreta": false
                },
                {
                    "id": 3112,
                    "descricao": "EM UMA ESCOLA",
                    "alternativaCorreta": false
                },
                {
                    "id": 3113,
                    "descricao": "NA PISTA ",
                    "alternativaCorreta": true
                }
            ],
            "imagensQuestao": [
                {
                    "id": 327,
                    "url": "https://pilotar.blob.core.windows.net/imagens-questoes-pilotar/64472b8b-f8e9-46df-b127-d7acaa7e9efb.png?st=2020-02-13T12%3A24%3A24Z&se=2021-02-13T12%3A24%3A00Z&sp=rl&sv=2018-03-28&sr=c&sig=RwwQGlYbjycDUzx%2F%2B%2FSkkf6QSPIdalFMsRFA3TpN9KY%3D"
                }
            ],
            "temaId": 1
        },
        {
            "descricaoQuestao": "A PLACA \"LARGURA M�XIMA PERMITIDA\" DETERMINA:",
            "id": 509,
            "alternativaSelecionada": 0,
            "alternativas": [
                {
                    "id": 1924,
                    "descricao": "AO MOTORISTA A LARGURA LIMITIDA",
                    "alternativaCorreta": false
                },
                {
                    "id": 1989,
                    "descricao": "AO MOTORISTA A LARGURA M�XIMA PERMITIDA ",
                    "alternativaCorreta": true
                },
                {
                    "id": 2024,
                    "descricao": "AO MOTORISTA A ALTURA M�XIMA PERMITIDA",
                    "alternativaCorreta": false
                },
                {
                    "id": 2124,
                    "descricao": "AO MOTORISTA O COMPRIMENTO M�XIMO PERMITIDO",
                    "alternativaCorreta": false
                }
            ],
            "imagensQuestao": [],
            "temaId": 1
        }
    ];
}

export default MultiplayerQuestion;
